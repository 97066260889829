@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: sticky;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  max-width: calc(var(--contentMaxWidthPages) + 48px);
  width: 100%;
  margin: 0 auto;
  padding: 30px 24px;
}
.searchMainContent {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    padding-top: 32px;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    display: block;
    padding-right: 30px;
    width: 100%;
    max-width: 300px;
  }
  & .filterSidebarHead {
    margin-bottom: 32px;
    & > h2 {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--textColor);
      font-size: 22px;
      line-height: 120%;
      font-family: var(--fontFamilySecondary);
      & .crossIcon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        transition: var(--transitionStyle);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: solid 1px var(--colorWhite);
        &:hover {
          background-color: var(--colorGrey50);
          border: solid 1px var(--colorGrey100);
          transition: var(--transitionStyle);
          box-shadow: var(--boxShadow);
        }
        & > svg {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

.filterColumnContent {
  width: 100%;
  & > * {
    margin-bottom: 24px;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);
  font-size: 14px;

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportMedium) {
    padding: 0 0px 0 24px;
    width: calc(100% - 300px);
    border-left: solid 1px var(--colorGrey100);
  }
  &.fullWidth {
    border-left: none;
    padding: 0px;
    width: 100%;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
  margin-top: 14px;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  & > * {
    font-size: 12px;
    line-height: 100%;
    color: #605f5f;
  }
  & > a {
    color: #605f5f;
    transition: var(--transitionStyle);
    &:hover {
      color: var(--marketplaceColor);
      transition: var(--transitionStyle);
    }
  }
  & > span {
    color: #605f5f;
  }
  & > svg {
    fill: none;
    width: 10px;
    height: 10px;
    margin: 0 10px;
  }
}
.searchPageBanner {
  background-image: url('../../assets/search-banner-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 24px;
  @media (--viewportLarge) {
    padding: 120px 24px;
  }
  & .searchPageBannerContent {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.fluteTypeCategory {
  margin-bottom: 32px;
  & > label {
    font-size: 14px;
    color: var(--colorGrey900);
    font-weight: var(--fontWeightSemiBold);
    padding: 0;
    margin: 0 0 16px 0;
  }
  & .subCategoryOptionContainer {
    max-height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: var(--colorGrey200);
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--colorGrey400);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--colorGrey400);
    }
    & .subCategoryOption {
      font-size: 14px;
      color: var(--colorGrey500);
      font-weight: var(--fontWeightSemiBold);
      padding: 4px 0;
      &:hover {
        cursor: pointer;
        transition: var(--transitionStyle);
        color: var(--colorBlack);
      }
      &.activeOption {
        &.activeSubcategory {
          cursor: pointer;
          text-decoration: underline;
          color: var(--colorBlack);
        }
      }
    }
  }
}
