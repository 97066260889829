@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.createListingLink,
.resetAllFiltersButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  display: inline;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
  }
}

.resetFilterSec {
  margin-top: 24px;
  display: flex;
  align-items: center;
  & > * {
    padding: 0;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}
