@import '../../../styles/customMediaQueries.css';

.root {
  padding-top: 16px;
  font-family: var(--fontFamilySecondary);
}

.filterHeader {
  line-height: 24px;
  margin-bottom: 8px;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.label,
.labelSelected {
  font-family: var(--fontFamilySecondary);
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  color: var(--colorBlack);

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.label {
  color: var(--colorBlack);
}

.labelSelected {
  color: var(--colorBlack);
}

.openSign {
  float: right;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--colorGrey700);

  &:hover {
    color: var(--marketplaceColor);

    & * {
      stroke: var(--marketplaceColor);
    }
  }
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
}

.optionsContainerOpen {
  height: auto;
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.optionBorder,
.optionBorderSelected {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width var(--transitionStyleButton);

  @media (--viewportMedium) {
    height: calc(100% - 8px);
    top: 8px;
  }
}

/* left animated "border" like hover element */
.optionBorder {
  width: 0;
  background-color: var(--colorBlack);
}

/* left static border for selected element */
.optionBorderSelected {
  width: 8px;
  background-color: var(--colorBlack);
}

.option {
  font-family: var(--fontFamilySecondary);
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  color: var(--colorGrey500);
  text-align: left;

  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 4px 0px;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.optionSelected {
  composes: option;
  color: var(--colorBlack);
}

.optionHighlight {
  color: var(--colorBlack);

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }
}

.clearButton {
  composes: h5 from global;
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
  @media (--viewportMedium) {
    margin: 0;
    padding: 4px 0 4px 0;
  }
}
