@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.labelPlain {
  color: var(--labelColor);
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  margin-bottom: 8px;
  display: block;
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  border: none;
}

.fieldPlain {
  position: relative;
  composes: h4 from global;
  margin: 0;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    flex-grow: 1;
    position: relative;
    z-index: 0;
    & > input {
      padding-right: 45px;
    }
  }
  & .searchIcon {
    position: absolute;
    z-index: 0;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

.fieldPlainLabel {
  padding: 4px 0 4px 0;
}
