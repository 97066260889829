@import '../../../styles/customMediaQueries.css';
.selectMultiFilterPlain {
  & > label {
    color: var(--labelColor);
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
    margin-bottom: 8px;
    display: block;
  }
  & input {
    min-height: 35px;
  }
}
