@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    /* sticky result summary in mobile */
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.searchResultSummary {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: var(--colorGrey600);
  font-weight: var(--fontWeightRegular);
}

.buttons {
  display: flex;
  & > * {
    &:not(:last-child) {
      margin-right: 24px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  max-width: 90%;
  padding: 24px 24px 94px 24px;
  margin: 0px;
  position: relative;
  overflow-y: auto;
  background-color: var(--colorWhite);
  & > button {
    position: absolute;
    background-color: var(--colorWhite);
    width: 100%;
    justify-content: flex-end;
  }
}

.modalHeadingWrapper {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalHeading {
  composes: h1 from global;
  margin-top: 0;
  font-size: 22px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--textColor);
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  float: right;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 40px;
  & > * {
    margin-bottom: 24px;
  }
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 90%;
  padding: 18px 24px;
  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
  padding: 4px 14px;
}

.filterBtn {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 100%;
  color: var(--textColor);
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  min-height: auto;
  height: auto;
  & > svg {
    margin-left: 6px;
  }
}
