.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
}

.searchResultSummary {
  composes: textSmall from global;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  color: #605f5f;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}
.rightFilters {
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}
.filterBtn {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 100%;
  color: var(--textColor);
  cursor: pointer;
  & > svg {
    margin-left: 6px;
  }
}
.filtersApplied {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  & .filterPill,
  & .clearfilterPill {
    background-color: var(--colorGrey50);
    border: solid 1px var(--colorGrey100);
    border-radius: var(--borderRadiusMedium);
    padding: 4px 10px;
    font-size: 12px;
    font-weight: var(--fontWeightMedium);
    color: var(--textColor);
    cursor: pointer;
    transition: var(--transitionStyle);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transition: var(--transitionStyle);
      border: solid 1px var(--colorGrey200);
      background-color: var(--colorWhite);
    }
    & .remove {
      width: 16px;
      height: 16px;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      background-color: var(--colorGrey100);
      font-size: 18px;
      font-weight: 300;
      line-height: 0;
      transition: var(--transitionStyle);
      &:hover {
        transition: var(--transitionStyle);
        background-color: var(--marketplaceColor);
        color: var(--colorWhite);
      }
    }
  }
  & .clearfilterPill {
    border-color: transparent;
    background-color: transparent;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      border-color: transparent;
      background-color: transparent;
    }
  }
}
