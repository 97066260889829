@import '../../../styles/customMediaQueries.css';

.subsubCategories {
  padding: 8px 0 0px 40px;
}

.megaDropdownMenu {
  max-height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: var(--colorGrey200);
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--colorGrey400);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--colorGrey400);
  }
  & .category {
    & .navbarCategories {
      padding: 4px 4px 4px 24px;
      font-weight: var(--fontWeightSemiBold);
      font-size: 14px;
      color: var(--colorGrey500);
      transition: var(--transitionStyle);
      &:hover {
        cursor: pointer;
        transition: var(--transitionStyle);
        color: var(--colorBlack);
      }
      &.activeSubcategory {
        cursor: pointer;
        color: var(--colorBlack);
      }
    }
  }
  & .subCategoriesWrapper {
    & .subcategories {
      & .verticalNavbarLink {
        font-weight: var(--fontWeightSemiBold);
        font-size: 14px;
        color: var(--colorGrey500);
        transition: var(--transitionStyle);
        padding: 4px 4px 4px 48px;
        &:hover {
          cursor: pointer;
          transition: var(--transitionStyle);
          color: var(--colorBlack);
        }
        &.activeSubcategory {
          cursor: pointer;
          text-decoration: underline;
          color: var(--colorBlack);
        }
      }
    }
  }
}
